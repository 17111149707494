@import url(https://fonts.googleapis.com/css?family=Fredoka+One&display=swap);
.board-container {
    background: lightblue;
    border: 8px solid rgba(255,0,0,0.3);
    z-index: -1;
}
.snake-head {
    position: absolute;
    color: orange;
    -webkit-animation: colors 2s infinite ;
            animation: colors 2s infinite ;
}

.up {
    transform: rotate(0);
}

.left {
    transform: rotate(270deg);
}

.down {
    transform: rotate(180deg);
}

.right {
    transform: rotate(90deg);
}


@-webkit-keyframes colors {
    0% {color: orange}
    50% {color: red}
    100% {color: orange}
}


@keyframes colors {
    0% {color: orange}
    50% {color: red}
    100% {color: orange}
}
.snake-segment {
    position: absolute;
    color: orange;
    -webkit-animation: colors 2s infinite ;
            animation: colors 2s infinite ;
}


@-webkit-keyframes colors {
    0% {color: orange}
    50% {color: red}
    100% {color: orange}
}


@keyframes colors {
    0% {color: orange}
    50% {color: red}
    100% {color: orange}
}
.controll-panel-container {
    position: absolute;
    display:flex;
    left: 50%;
    background-color: darkblue;
    border-radius: 15px;
    transform: translate(-50%);
    padding: 3px 3px 0;
    transition: top 0.2s;
    z-index: 2;
    align-items: center;
}

.controll-panel-container button {
    background-color: transparent;
    border: none;
    color: white;
    margin-right: 10px;
    cursor: pointer;
}
.controll-panel-container button:hover {
    color: yellow;
}
.controll-panel-container button:focus {
    border:none;
}

.controll-panel-container button:last-child {
    margin-right: 0;
}

.controll-panel-container .score {
    position: relative;
    display: inline-block;
    color: darkblue;
    background-color: white;
    font-size: 16px;
    padding: 1px;
    border-radius: 5px;
    top: -2px;
    margin-right: 2px;
}
.display-food-container {
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
}

.message-panel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 0;
    width: 250px;
    height: 250px;
    transform: translate(-50%,-50%);
}

.message-panel-container svg {
    color: rgba(255,255,255,0);
    -webkit-animation: show-image 0.2s forwards;
            animation: show-image 0.2s forwards;
}

.message-panel-container .message {
    color: rgba(0,0,0,0);
    font-family: arial, sans-serif;
    font-weight: bold;
    -webkit-animation: show-message 0.2s forwards;
            animation: show-message 0.2s forwards;
    padding: 3px;
    border: 2px gray solid;
    border-radius: 10px;
}

@-webkit-keyframes show-image {
    to {color: rgba(255,255,255,0.4)}
}

@keyframes show-image {
    to {color: rgba(255,255,255,0.4)}
}

@-webkit-keyframes show-message {
    to {color: rgba(0,0,0,0.5);}
}

@keyframes show-message {
    to {color: rgba(0,0,0,0.5);}
}
.gameover_container{
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 30;
    justify-content: center;
    align-items: center;
}

.gameover_container .gameover_text {
    font-family: 'Fredoka One', cursive;
    font-size: 100px;
    color: white;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.brand-link {
    position:fixed;
    bottom: 16px;
    left: 16px;
    color: white;
    text-decoration: none;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    cursor: pointer;
    display: inline-block;
}

.brand-link:visited {
    color: white;
}
.rank-cells-container {
    width: 100%;
    padding:3px;
    background-color: rgba(0,0,0,0.1);
    display: flex;
    margin: 3px 0;
    border-radius: 35px;
}

.rank-cells-container img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
}

.rank-cells-container .text-container  {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    padding-left:10px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.rank-cells-container .text-container .points {
    color: red;
    font-weight: bold;
}

.rank-cells-container .text-container .name {
    color: navy;
}
.rank-container {
    position: fixed;
    display:flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255,255,255,0.5);
    right: -234px;
    top:100px;
    border-radius: 16px;
    overflow-y: auto;
    width:250px;
    height:370px;
    z-index: 3;
    border-color:4px solid red;
    transition: right 0.5s;
    padding: 3px;
    opacity: 0;
}

.rank-container:hover {
    right: 0px;
}

.rank-container .title {
    padding: 10px;
    font-size: 24px;
    color: brown;
    font-family: 'Fredoka One', cursive;
    letter-spacing: 3px;
}
